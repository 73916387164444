import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDDExxHMzp_236hZnAKQo-ba0pc3wAwdN4",
  authDomain: "gt-excel-portal.firebaseapp.com",
  databaseURL: "https://gt-excel-portal.firebaseio.com",
  projectId: "gt-excel-portal",
  storageBucket: "gt-excel-portal.appspot.com",
  messagingSenderId: "1033672830212",
  appId: "1:1033672830212:web:298dfc7195cf7b66643451",
  measurementId: "G-PDE0F4CYV0",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();

ReactDOM.render(
  <React.StrictMode>
    <App db={db} auth={auth} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
