import React, { useEffect, useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import './Directory.scss'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Divider, Modal } from '@material-ui/core'

import { FirebaseContext } from '../../contexts/FirebaseContext'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  controls: {
    alignItems: 'center',
    margin: 'auto',
  },
  root: {
    justifyContent: 'center',
  },
  other: {
    alignItems: 'left',
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paper2: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  flexContainer: {
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardCenter: {
    padding: 1,
    margin: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginBottom: '2',
  },
  cardBottom: {
    marginBottom: '2',
  },
  searchFacets: {
    padding: 10,
  },
}))

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const DirectoryCard = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [confirmModel, setConfirmModel] = useState(false)
  const [modalStyle] = React.useState(getModalStyle);
  const context = useContext(FirebaseContext)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAddFriend = (id) => {
    let user = context.publicUser
    let userId = context.userPublicId
    if (user) {
      user.friendIds = [...user.friendIds, id]
      context.updatePublicUser(user, userId)
    }
  }

  const handleDeleteFriend = (id) => {
    let user = context.publicUser
    let userId = context.userPublicId
    if (user) {
      user.friendIds = user.friendIds.filter((friendId) => {
        return friendId !== id
      })
      context.updatePublicUser(user, userId)
    }
  }

  const handleMakeAdmin = () => {
    context.makeAdmin(props.privateID)
  }

  const handleRemoveAdmin = () => {
    context.removeAdmin(props.privateID)
  }

  const suspendUser = () => {
    context.suspendUser(props.privateID)
  }

  const unSuspendUser = () => {
    context.unsuspendUser(props.privateID)
  }
  const handleResetPassword = () => {
    function onDone(event) {
      if(event.error == null) {
        setConfirmModel(false)
      }
    }
    let user = {
      email: props.email
    }
    context.resetUserPassword(user, onDone) 
  }

  const handleConfirmModel = () => {
    setConfirmModel(true)
  }

  const colors = createMuiTheme({
    palette: {
      primary: {
        // Dark Blue 
        main:'#003057',
      },
    }
  });

  let friendButon
  if (context.publicUser && context.publicUser.friendIds && !context.publicUser.friendIds.includes(props.id)) {
    friendButon = (
      <ThemeProvider theme={colors}>
      <Button size="small" onClick={() => handleAddFriend(props.id)} variant="contained" color="primary">
        Add Friend
      </Button>
      </ThemeProvider>
    )
  } else {
    friendButon = (
      <ThemeProvider theme={colors}>
      <Button size="small" onClick={() => handleDeleteFriend(props.id)} variant="contained" color="primary">
        Delete Friend
      </Button>
      </ThemeProvider>
    )
  }

  let makeAdminButton = (
    <ThemeProvider theme={colors}>
    <Button variant="contained" color="primary" size="small" onClick={handleMakeAdmin}>
      Make Admin
    </Button>
    </ThemeProvider>
  )
  if (props.permissions && props.permissions.includes('admin')) {
    makeAdminButton = (
      <ThemeProvider theme={colors}>
      <Button variant="contained" color="primary" size="small" onClick={handleRemoveAdmin}>
        Remove Admin
      </Button>
      </ThemeProvider>
    )
  }

  let suspendButton = (
    <ThemeProvider theme={colors}>
    <Button variant="contained" color="primary" size="small" onClick={suspendUser}>
      Suspend
    </Button>
    </ThemeProvider>
  )
  if (props.permissions && props.permissions.includes('suspended')) {
    suspendButton = (
      <ThemeProvider theme={colors}>
      <Button variant="contained" color="primary" size="small" onClick={unSuspendUser}>
        Un-suspend
      </Button>
      </ThemeProvider>
    )
  }
  let resetPasswordButton = (
    <ThemeProvider theme={colors}>
      <Button variant="contained" color="primary" size="small" onClick={handleConfirmModel}>
        Reset Password
      </Button>
    </ThemeProvider>
  )
  const isAdmin = context && context.privateUser && context.privateUser.permissions.includes('admin')
  const body = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 >Are you sure you want to do this?</h2>
      <p >
        This will send an email to the user prompting them to create a new password
      </p>
      <Button variant="contained" color="secondary" size="size" onClick={handleResetPassword}>
          Reset Password
      </Button>
    </div>
  );
  return (
    <Grid>
      <CardContent>
        <div className={classes.cardCenter}>
          <Button variant="outlined" onClick={handleClickOpen}>
            <Typography variant="body1" component="p">
              {props.fname} {props.lname}
            </Typography>
          </Button>
          <Dialog onClose={handleClose} aria-labelledby="Profile" open={open}>
            <DialogTitle alignItems="center" id="form-dialog-title">
              {props.fname} {props.lname}
            </DialogTitle>

            <DialogContent>
              <Typography variant="body1" component="p">
                Email: {props.email}
              </Typography>
              <Typography variant="body1" component="p">
                Phone Number: {props.phoneNumber}
              </Typography>
              <Typography variant="body1" component="p">
                Facebook: {props.facebook}
              </Typography>
              <Typography variant="body1" component="p">
                Twitter: {props.twitter}
              </Typography>
              <Typography variant="body1" component="p">
                City, State: {props.location}
              </Typography>
              <Typography variant="body1" component="p">
                Interests: {props.interests}
              </Typography>

              {isAdmin && (
                <div>
                  <Divider />
                  <h3>Private info (only visible to admins):</h3>
                  <Typography variant="body1" component="p">
                    Address: {props.privateUser.address}
                  </Typography>
                  <Typography variant="body1" component="p">
                    Current employment: {props.privateUser.currentCompany}
                  </Typography>
                  <Typography variant="body1" component="p">
                    Current position: {props.privateUser.currentPosition}
                  </Typography>
                  <Typography variant="body1" component="p">
                    Employer email: {props.privateUser.employerEmail}
                  </Typography>
                  <Typography variant="body1" component="p">
                    Alternate contact: {props.privateUser.alternateContactName}
                  </Typography>
                  <Typography variant="body1" component="p">
                    Alternate contact phone: {props.privateUser.alternateContactNumber}
                  </Typography>
                  <Typography variant="body1" component="p">
                    Alternate contact email: {props.privateUser.alternateContactEmail}
                  </Typography>
                </div>
              )}
              <div className="centerMe">{friendButon}</div>
              {isAdmin ? (
                <div className="adminPowers">
                  {makeAdminButton} {''}
                  {suspendButton} {''}
                  {resetPasswordButton}
                </div>
              ) : null}
            </DialogContent>
            <Modal
                  open={confirmModel}
                  onClose={() => {setConfirmModel(false)}}
                >
                  {body}
            </Modal>
          </Dialog>
        </div>
      </CardContent>
    </Grid>
  )
}

const Directory = () => {
  const classes = useStyles()

  const [names, setNames] = useState(false)
  const context = useContext(FirebaseContext)
  const [publicUser, setPublicUser] = useState({})
  const [nameSearch, setNameSearch] = useState('')
  const [locationSearch, setLocationSearch] = useState('')
  const [interestSearch, setInterestSearch] = useState('')
  const [onlyFriends, setOnlyFriends] = useState(false)

  const colors = createMuiTheme({
    palette: {
      primary: {
        // Dark Blue 
        main:'#003057',
      },
    }
  });

  useEffect(() => {
    let isAdmin = context?.privateUser?.permissions?.includes('admin') ?? false
    if (!isAdmin) {
      context.database.collection('publicUser').onSnapshot((snapshot) => {
        let nameList = []
        snapshot.docs.forEach((user) => nameList.push({ ...user.data(), id: user.id, permissions: null }))
        setNames(nameList)
      })
    } else {
      context.database.collection('privateUser').onSnapshot(async (snapshot) => {
        let nameList = []
        let promiseBucket = []
        snapshot.docs.forEach((privateUser) => {
          let getUser = context.database
            .collection('publicUser')
            .doc(privateUser.data().publicId)
            .get()
            .then((publicUserRaw) => {
              nameList.push({
                ...publicUserRaw.data(),
                id: publicUserRaw.id,
                privateID: privateUser.id,
                permissions: privateUser.data().permissions,
                privateUser: privateUser.data(),
              })
            })
          promiseBucket.push(getUser)
        })
        await Promise.all(promiseBucket)
        setNames(nameList)
      })
    }
    setPublicUser(context.publicUser)
  }, [context.database, context.publicUser, context.privateUser])

  let renderList
  if (names) {
    renderList = names?.filter((user) => {
      let correctName = false
      let correctLocation = false
      let correctInterst = false

      if (nameSearch === '') {
        correctName = true
      }

      if (locationSearch === '') {
        correctLocation = true
      }

      if (interestSearch === '') {
        correctInterst = true
      }

      return (
        (correctName || (user.fname + user.lname).toLowerCase().includes(nameSearch.toLowerCase())) &&
        (correctLocation || user.location?.toLowerCase().includes(locationSearch.toLowerCase())) &&
        (correctInterst || user.interests.toLowerCase().includes(interestSearch.toLowerCase())) &&
        (!onlyFriends || publicUser.friendIds.includes(user.id))
      )
    })
  }

  return (
    <ThemeProvider theme={colors}>
    <div>
      <div className={classes.controls}>
        <Card>
          <CardContent>
            <div className={classes.flexContainer}>{/* <MenuBookIcon style={{ fontSize: 50 }}/> */}</div>
            <Typography variant="h4" color="primary" align="center">
              Directory
            </Typography>
            <div className="Filter">
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    className={classes.searchFacets}
                    onChange={(event) => {
                      setNameSearch(event.target.value)
                    }}
                    placeholder="Search Name..."
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                  ></TextField>
                </AccordionDetails>{' '}
                <AccordionDetails>
                  <TextField
                    className={classes.searchFacets}
                    onChange={(event) => {
                      setLocationSearch(event.target.value)
                    }}
                    placeholder="Search Location..."
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                  ></TextField>
                </AccordionDetails>{' '}
                <AccordionDetails>
                  <TextField
                    className={classes.searchFacets}
                    onChange={(event) => {
                      setInterestSearch(event.target.value)
                    }}
                    placeholder="Search Interests..."
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                  ></TextField>
                </AccordionDetails>{' '}
                <AccordionDetails>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          setOnlyFriends(!onlyFriends)
                        }}
                      ></Checkbox>
                    }
                    label="Only Friends"
                  />
                </AccordionDetails>
              </Accordion>
              

              {renderList &&
                renderList.map((user, i) => {
                  return (
                    <DirectoryCard
                      key={i}
                      publicUser={user}
                      fname={user.fname}
                      lname={user.lname}
                      email={user.contactInfo.email}
                      facebook={user.facebook}
                      twitter={user.twitter}
                      phoneNumber={user.phoneNumber}
                      location={user.location}
                      interests={user.interests}
                      id={user.id}
                      friendIds={user.friendIds}
                      permissions={user.permissions}
                      privateID={user.privateID}
                      privateUser={user.privateUser}
                    />
                  )
                })}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
    </ThemeProvider>
  )
}

export default Directory
