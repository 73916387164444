import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./CreateReply.scss";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import ReplyIcon from "@material-ui/icons/Reply";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";


const CreateReply = (props) => {
  const [open, setOpen] = React.useState(false);
  const [desc, setDesc] = useState("");
  const context = useContext(FirebaseContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePost = () => {
    var comment = {content:desc, parentType:"post", parentId: props.parentId};
    context.createComment(comment);
    setOpen(false);
  };

  const colors = createMuiTheme({
    palette: {
      primary: {
        // Dark Blue 
        main:'#003057',
      },
      secondary: {
        // black
        main:'#000',
      },
    }
  });

  return (
    <ThemeProvider theme={colors}>
    <div>
      <Button
        className="add-button"
        variant="contained"
        size="large"
        color="primary"
        aria-label="add"
        onClick={handleClickOpen}
      >
        <ReplyIcon/>
        Reply
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Reply</DialogTitle>
        <DialogContent>
          <TextField
            label="Type reply here"
            multiline
            rows={4}
            variant="outlined"
            onChange={(event) => setDesc(event.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePost} variant="outlined" color="seconday">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </ThemeProvider>
  );
};

CreateReply.propTypes = {
  parentId: PropTypes.string.isRequired
};

export default CreateReply;
