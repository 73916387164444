import React, { useContext } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { FirebaseContext } from './contexts/FirebaseContext'
import { LinearProgress } from '@material-ui/core'

const SecureRoute = withRouter((props) => {
  const context = useContext(FirebaseContext)
  console.log(`[SecureRoute] contextLoaded: ${context.userLoaded}, userPublicId: ${context.userPublicId}`)
  if (!context.userLoaded) {
    return <LinearProgress />
  } else {
    // User is loaded
    if (
      !context.privateUser ||
      !context.publicUser ||
      !context.privateUser.permissions ||
      !context.userPrivateId ||
      !context.userPublicId
    ) {
      props.history.push('/login')
      return <div />
    }
    if (props.adminOnly && !context.privateUser.permissions.includes('admin')) {
      props.history.push('/login')
    }
    const { component: Component, ...rest } = props
    document.title = `${props.title || 'Excel Alumni Portal'}`
    return <Route exact {...rest} render={(props) => <Component {...rest} {...props} />} />
  }
})

SecureRoute.propTypes = {
  component: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default SecureRoute
