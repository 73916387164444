import React, { useEffect, useContext, useState } from 'react'
import { FirebaseContext } from '../../contexts/FirebaseContext'

import {
  ThemeProvider,
  Divider,
  TextField,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  InputAdornment,
  MenuItem,
} from '@material-ui/core'
import { makeStyles, createMuiTheme } from '@material-ui/core/styles'

import './Profile.scss'

import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import PhoneIcon from '@material-ui/icons/Phone'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import FavoriteIcon from '@material-ui/icons/Favorite'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import BusinessIcon from '@material-ui/icons/Business'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import WorkIcon from '@material-ui/icons/Work'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import SchoolIcon from '@material-ui/icons/School'
import Avatar from '@material-ui/core/Avatar'

const colors = createMuiTheme({
  palette: {
    primary: {
      // Dark Blue
      main: '#003057',
    },
    secondary: {
      // Black
      main: '#000',
    },
  },
})

const useStyles = makeStyles((theme) => ({
  controls: {
    alignItems: 'center',
    margin: 'auto',
  },
  root: {
    justifyContent: 'center',
    backgroundColor: '#0003057',
  },
  other: {
    alignItems: 'left',
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  flexContainer: {
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardCenter: {
    padding: 1,
    margin: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardBottom: {
    marginBottom: '2',
  },
  searchFacets: {
    padding: 10,
  },
}))

const Profile = () => {
  const classes = useStyles()

  const [isEditMode, setIsEditMode] = useState(false)
  const context = useContext(FirebaseContext)
  const [publicUser, setPublicUser] = useState({})
  const [privateUser, setPrivateUser] = useState({})

  useEffect(() => {
    setPublicUser(context.publicUser)
    setPrivateUser(context.privateUser)
  }, [context.publicUser, context.privateUser])

  const handleUpdateProfile = () => {
    let cb
    context.updatePublicUser(publicUser, context.userPublicId, cb)
    context.updatePrivateUser(privateUser, context.userPrivateId, cb)
  }

  return (
    <ThemeProvider theme={colors}>
      <div>
        <div className={classes.controls}>
          <Card>
            <CardContent>
              <Typography variant="h3" color="primary" align="center">
                My Profile
              </Typography>
              <div class={classes.flexContainer}>
                <div className="avatar">
                  <Avatar
                    style={{ fontSize: 40 }}
                    className="avatarColor"
                    alt="0"
                    aria-label="personIcon"
                    align="center"
                  >
                    {context.publicUser.fname.charAt(0) + context.publicUser.lname.charAt(0)}
                  </Avatar>
                </div>
              </div>
              {!isEditMode && (
                <div>
                  <div class="viewCard">
                    <Typography variant="h4" color="primary" align="center">
                      {context.publicUser.fname} {context.publicUser.lname}
                    </Typography>
                  </div>
                  <div class="viewCard">
                    <Typography variant="h6" color="primary" align="center">
                      Email: {context.publicUser.contactInfo.email}
                    </Typography>
                  </div>
                  <div class = "viewCard">
                    <Typography variant="h6" color="primary" align="center">
                      Phone Number: {context.publicUser.contactInfo.phoneNumber}
                    </Typography>
                  </div>
                  <div class="viewCard">
                    <Typography variant="h6" color="primary" align="center">
                      Facebook: {context.publicUser.contactInfo.facebook}
                    </Typography>
                  </div>
                  <div class="viewCard">
                    <Typography variant="h6" color="primary" align="center">
                      Twitter: {context.publicUser.contactInfo.twitter}
                    </Typography>
                  </div>
                  <div class="viewCard">
                    <Typography variant="h6" color="primary" align="center">
                      City, State: {context.publicUser.location}
                    </Typography>
                  </div>
                  <div class="viewCard">
                    <Typography variant="h6" color="primary" align="center">
                      Interests: {context.publicUser.interests}
                    </Typography>
                  </div>
                </div>
              )}
            </CardContent>
            {isEditMode && (
              <CardContent>
                <Divider />
                <div class={classes.flexContainer}>
                  <h2>Public Information:</h2>
                </div>
                <div class={classes.flexContainer}>
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="First name"
                    variant="outlined"
                    onChange={(event) => {
                      publicUser.fname = event.target.value
                    }}
                    defaultValue={context.publicUser.fname}
                  />
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Last name"
                    variant="outlined"
                    onChange={(event) => {
                      publicUser.lname = event.target.value
                    }}
                    defaultValue={context.publicUser.lname}
                  />
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Graduation year"
                    variant="outlined"
                    onChange={(event) => {
                      publicUser.graduationYear = event.target.value
                    }}
                    defaultValue={context.publicUser.graduationYear}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SchoolIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {context.publicUser.type === 'GT Excel Alumni' ||
                    (context.publicUser.type === 'GT Excel Current Student' && (
                      <TextField
                        className="fname-tb"
                        id="outlined-basic"
                        select
                        label="Role"
                        variant="outlined"
                        style={{ width: '200px' }}
                        defaultValue={context.publicUser.type}
                      >
                        <MenuItem
                          autoFocus
                          selected
                          value="GT Excel Alumni"
                          onClick={() => (publicUser.type = 'GT Excel Alumni')}
                        >
                          Alumni
                        </MenuItem>
                        <MenuItem
                          value="GT Excel Current Student"
                          onClick={() => (publicUser.type = 'GT Excel Current Student')}
                        >
                          Current student
                        </MenuItem>
                      </TextField>
                    ))}
                </div>
                <div class={classes.flexContainer}>
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Twitter"
                    variant="outlined"
                    onChange={(event) => {
                      publicUser.contactInfo.twitter = event.target.value
                    }}
                    defaultValue={publicUser.contactInfo.twitter}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TwitterIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Facebook"
                    variant="outlined"
                    onChange={(event) => {
                      publicUser.contactInfo.facebook = event.target.value
                    }}
                    defaultValue={publicUser.contactInfo.facebook}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FacebookIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    onChange={(event) => {
                      publicUser.contactInfo.phoneNumber = event.target.value
                    }}
                    defaultValue={publicUser.contactInfo.phoneNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div class={classes.flexContainer}>
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="City, State"
                    variant="outlined"
                    onChange={(event) => {
                      publicUser.location = event.target.value
                    }}
                    defaultValue={publicUser.location}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonPinCircleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Interests"
                    variant="outlined"
                    onChange={(event) => {
                      publicUser.interests = event.target.value
                    }}
                    defaultValue={publicUser.interests}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FavoriteIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <Divider />
                <div class={classes.flexContainer}>
                  <h2>Private Information (hidden from profile):</h2>
                </div>
                <div class={classes.flexContainer}>
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Current address"
                    variant="outlined"
                    onChange={(event) => {
                      privateUser.address = event.target.value
                    }}
                    defaultValue={privateUser.address}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HomeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div class={classes.flexContainer}>
                  <h3>Current Employment</h3>
                </div>
                <div class={classes.flexContainer}>
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Company"
                    variant="outlined"
                    onChange={(event) => {
                      privateUser.currentCompany = event.target.value
                    }}
                    defaultValue={privateUser.currentCompany}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BusinessIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Position"
                    variant="outlined"
                    onChange={(event) => {
                      privateUser.currentPosition = event.target.value
                    }}
                    defaultValue={privateUser.currentPosition}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <WorkIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Employer Email Address"
                    variant="outlined"
                    onChange={(event) => {
                      privateUser.employerEmail = event.target.value
                    }}
                    defaultValue={privateUser.employerEmail}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AlternateEmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div class={classes.flexContainer}>
                  <h3>Alternate Contact Information</h3>
                </div>
                <div class={classes.flexContainer}>
                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    onChange={(event) => {
                      privateUser.alternateContactName = event.target.value
                    }}
                    defaultValue={privateUser.alternateContactName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    onChange={(event) => {
                      privateUser.alternateContactNumber = event.target.value
                    }}
                    defaultValue={privateUser.alternateContactNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ContactPhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    className="fname-tb"
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    onChange={(event) => {
                      privateUser.alternateContactEmail = event.target.value
                    }}
                    defaultValue={privateUser.alternateContactEmail}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ContactMailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </CardContent>
            )}
            <CardActions className={classes.root}>
              {!isEditMode && (
                <Button onClick={() => setIsEditMode(true)} variant="contained" color="primary" size="large">
                  Edit Profile
                </Button>
              )}
              {isEditMode && (
                <Button
                  onClick={() => {
                    setIsEditMode(false)
                    handleUpdateProfile()
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Save Changes
                </Button>
              )}
            </CardActions>
          </Card>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Profile
