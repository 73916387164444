import React, { useEffect, useContext } from "react";
import { CircularProgress, TextField } from "@material-ui/core";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import CareerCard from './CareerCard';
import CommunityCard from './CommunityCard';
import "./Feed.scss";
import MenuItem from "@material-ui/core/MenuItem"
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

const Feed = (props) => {
  const [posts, setPosts] = React.useState([]);
  const context = useContext(FirebaseContext);
  const [searchRoles, setSearchRoles] = React.useState('')
  const [searchContent, setSearchContent] = React.useState('')


  useEffect(() => {
    return context.database
      .collection("posts")
      .onSnapshot((snapshot) => {
        let postList = [];
        let promiseBucket = []
        for(let post of snapshot.docs) {
          if(post.data().type === props.type) {
            promiseBucket.push(context.database.collection('publicUser').doc(post.data().authorId).get().then(doc => {
              postList.push({...post.data(), id: post.id, authorRole: doc.data().type})
            }))
          }
        }
        Promise.all(promiseBucket).then(() => {
          setPosts(postList);
        });
      });
  }, [props.type, context.database]);

  let renderPosts = posts.filter(post => {
    let includePost = true;
    if (searchRoles) {
      if (!post.authorRole.toLowerCase().includes(searchRoles.toLowerCase())) {
        includePost = false;
      }
    }
    if (searchContent) {
      if (!post.content.text.toLowerCase().includes(searchContent.toLowerCase()) && !post.title.toLowerCase().includes(searchContent.toLowerCase())) {
        includePost = false;
      }
    }
    return includePost;
  })

  renderPosts = renderPosts.sort((a, b) => {
    let dateA = new Date(a.createdAtDate).getTime();
    let dateB = new Date(b.createdAtDate).getTime();
    let pinnedA = a.pinned;
    if(dateA > dateB || pinnedA) {
      return -1;
    }
    if(dateA < dateB) {
      return 1;
    }
    return 0;
  });
  
  return (
    <div>
      
      <div className = "filter">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Filter</Typography>
        </AccordionSummary>
        <AccordionDetails >
        
        <TextField
            style={{ width: "700" }}
            id="outlined-basic"
            select
            label="Filter posts by role"
            variant="outlined"
            size= "small"
            fullWidth
          >
            <MenuItem dense value="undefined" onClick={() => setSearchRoles("")}>None</MenuItem>
            <MenuItem dense value="alumni" onClick={() => setSearchRoles("alumni")}>Alumni</MenuItem>
            <MenuItem dense value="family" onClick={() => setSearchRoles("family")}>Family</MenuItem>
            <MenuItem dense value="faculty" onClick={() => setSearchRoles("faculty")}>Faculty</MenuItem>
            <MenuItem dense value="peer" onClick={() => setSearchRoles("peer")}>Peer</MenuItem>
            <MenuItem dense value="current student" onClick={() => setSearchRoles("current student")}>Current Student</MenuItem>
            <MenuItem dense value="friend of alumni" onClick={() => setSearchRoles("friend")}>Friend of Alumni</MenuItem>
          </TextField>
          </AccordionDetails><AccordionDetails>
      <TextField 
      id = "outlined-basic" 
      variant = "outlined"
      size = "small"
      fullWidth
      onChange={(event) => setSearchContent(event.target.value)} placeholder="Search for content" 
      defaultValue=''>

      </TextField>
        </AccordionDetails>
      </Accordion>
      {!renderPosts && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
      {(renderPosts.length < 1) && !searchContent && !searchRoles && (
        <p className="no-card-text">No posts yet. Why not create one?</p>
      )}
      {(renderPosts.length < 1) && (searchContent || searchRoles) && (
        <p className="no-card-text">No posts matching the filters applied.</p>
      )}
      </div>
      {renderPosts &&
        renderPosts.map((post, i) => {
          if (post.type === "career") {
            return <CareerCard key={post.id} post={post} />
          } else if (post.type === "community") {
            return <CommunityCard key={post.id} post={post} />
          } else {
            console.log("[Feed] Post of invalid type found:");
            console.log(post);
            return <div />
          }
        })}
    </div>
  );
};

export default Feed;
