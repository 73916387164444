import React, { useState, useContext } from "react";
import { withRouter } from "react-router";
import { Button, TextField } from "@material-ui/core";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert.jsx';


import "./CreateAccount.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
})); 

const CreateAccount = withRouter((props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCon, setPasswordCon] = useState("");
  const [role, setRole] = useState(0);
  const [errMessage, setErrMessage] = useState("");
  const context = useContext(FirebaseContext);
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [samePass, setSamePass] = React.useState(true);


  const cb = (res) => {
    if (res && res.error) {
      setErrMessage(res.error.message);
    } else {
      setErrMessage(false);
      props.history.push("/feed");
    }
  };
  
  const handleAcceptPolicy = (event) => {
    setChecked(event.target.checked);
    setDisabled(!event.target.checked);
  };

  const handleCreateAccount = () => {
    if (password === passwordCon) {
      const user = {
        email: email,
        password: password,
        fname: firstName,
        lname: lastName,
        type: role
      };
      context.createAccountEmailPassword(user, cb);
    } else {
        setSamePass(false);
    }
  };

  return (
    <div className="Container">
      <Box
        color="white"
        bgcolor="#003057"
        p="1rem"
        fontSize="h4.fontSize"
        letterSpacing={2}
        textAlign="center"
        >
        WELCOME TO THE EXCEL ALUMNI PORTAL
      </Box>
      {samePass ? null : <ErrorAlert
                  error={
                    new Error('Passwords do not match. Please retype passwords.')
                  }
                  severity="warning"
                />}
      <Container maxWidth="sm">
        <p className={classes.root}></p>
        {errMessage && (
          <Alert className="alert" severity="error">
            {errMessage}
          </Alert>
        )}
        
        <div className="textField">
          <TextField
            required
            id="filled-full-width"
            fullWidth
            label="First Name"
            variant="outlined"
            onChange={(event) => setFirstName(event.target.value)}
          />
        </div>
        <div className="textField">
          <TextField
            required
            fullWidth
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            onChange={(event) => setLastName(event.target.value)}
          />
        </div>
        <div className="textField">
          <TextField
            required
            id="outlined-basic"
            fullWidth
            label="Email"
            variant="outlined"
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className="textField">
          <TextField
            required
            id="outlined-basic"
            fullWidth
            type="password"
            label="Password"
            variant="outlined"
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div className="textField">
          <TextField
            required
            id="outlined-basic"
            fullWidth
            type="password"
            label="Retype Password"
            variant="outlined"
            onChange={(event) => setPasswordCon(event.target.value)}
          />
        </div>
        <div className="textField">
        <TextField
          required
          fullWidth
          id="outlined-basic"
          select
          label="Select your role"
          variant="outlined"
        >
          <MenuItem value="alumni" onClick={() => setRole("GT Excel Alumni")}>Alumni</MenuItem>
          <MenuItem value="family" onClick={() => setRole("GT Excel Family")}>Family</MenuItem>   
          <MenuItem value="faculty" onClick={() => setRole("GT Excel Faculty")}>Faculty</MenuItem>
          <MenuItem value="peer" onClick={() => setRole("GT Excel Peers")}>Peer</MenuItem> 
          <MenuItem value="student" onClick={() => setRole("GT Excel Current Student")}>Current Student</MenuItem> 
          <MenuItem value="friend" onClick={() => setRole("GT Excel Friends of Alumni")}>Friend of Alumni</MenuItem> 

        </TextField>
        </div>
        <div className={classes.root}>
          <FormControlLabel
            style={{textAlign: 'center'}}
            color="inherit"
            control={<Checkbox checked={checked} 
            onChange={handleAcceptPolicy}
            name="acceptPolicy"
            color="inherit" />}
            label="Confirm you have read the privacy policy"
          />
        </div>
        <div className={classes.root}>
          <Link href="https://www.iubenda.com/privacy-policy/99541975"
            target="_blank"
            rel="noopener"
          >
            Privacy Policy
          </Link>
        </div>
        <div className={classes.root}>
          <Button 
            style={{
              backgroundColor: "#b3a369",
            }}
            size="large"
            variant="contained"
            disabled={disabled}
            onClick={handleCreateAccount}>
            Create Account
          </Button>
        </div>
      </Container>
    </div>
  );
});

export default CreateAccount;
