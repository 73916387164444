import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Button, TextField } from '@material-ui/core';
import { FirebaseContext } from '../../contexts/FirebaseContext';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import './CreateAccount.scss';
import './Login.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}))

const Login = withRouter((props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errMessage, setErrMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const context = useContext(FirebaseContext)
  const classes = useStyles()

  const cb = (res) => {
    if (res.error) {
      setErrMessage(res.error.message)
    } else {
      setSuccessMessage(res.message)
      setErrMessage(false)
      props.history.push('/feed')
    }
  }

  const onLogin = () => {
    context.loginEmailPassword({ email, password }, cb)
  }

  useEffect(() => {
    if (context.userLoaded && context.userPrivateId) {
      props.history.push('/feed')
    }
  }, [context.userPrivateId, context.userLoaded, props.history])

  return (
    <div className="Container">
      <Box 
        color="white" 
        bgcolor="#003057" 
        p="1rem" 
        fontSize="h4.fontSize" 
        letterSpacing={2}
        textAlign="center"
      >
        WELCOME TO THE EXCEL ALUMNI PORTAL
      </Box>
      <Container maxWidth="sm">
        <p className={classes.root}></p>
        {errMessage && (
          <Alert className="alert" severity="error">
            {errMessage}
          </Alert>
        )}
        {successMessage && !errMessage && (
          <Alert className="alert" severity="success">
            {successMessage}
          </Alert>
        )}
        <div className="input-email">
          <TextField
            id="outlined-basic"
            fullWidth
            label="Email"
            variant="outlined"
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <p></p>
        <div className="input-password">
          <TextField
            id="outlined-basic"
            fullWidth
            type="password"
            label="Password"
            variant="outlined"
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <p></p>
        <div className={classes.root}>
          <Button
            style={{
              backgroundColor: '#b3a369',
            }}
            size="large"
            variant="contained"
            onClick={onLogin}
          >
            Login
          </Button>
        </div>
        <div className={classes.root}>
          <Button 
            variant="text" 
            color="black" 
            onClick={() => props.history.push('/signup')}
          >
            Create Account
          </Button>
        </div>  
        </Container>
    </div>
  )
})

export default Login
