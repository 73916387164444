import React, { createContext, Component } from "react";
import firebase from "firebase";
import { useFatalError } from "../components/ErrorBoundary/ErrorBoundary";
export const FirebaseContext = createContext();

class FirebaseContextProvider extends Component {
  state = {
    user: null,
    privateUser: null,
    publicUser: null,
    userPrivateId: null,
    userPublicId: null,
    userLoaded: false,
    signingUp: false,
    database: null,
    loginEmailPassword: (credentials, cb) => 
      this.loginEmailPassword(credentials, cb),
    createAccountEmailPassword: (user, cb) =>
      this.createAccountEmailPassword (user, cb),
    userLogout: () =>
      this.userLogout (),
    deleteAccountEmailPassword: (id, cb) =>
      this.deleteAccountEmailPassword(id, cb),
    updatePublicUser: (publicUser, id, cb) =>
      this.updatePublicUser(publicUser, id, cb),
    updatePrivateUser: (privateUser, id, cb) =>
      this.updatePrivateUser(privateUser, id, cb),

    getPublicUser: (id) =>
      this.getPublicUser(id),

    createPost: (post, cb) =>
      this.createPost(post, cb),
    updatePost: (post, id, cb) =>
      this.updatePost(post, id, cb),
    deletePost: (id, cb) =>
      this.deletePost(id, cb),

    createComment: (comment, cb) =>
      this.createComment(comment, cb),
    updateComment: (comment, id, cb) =>
      this.updateComment(comment, id, cb),
    deleteComment: (id, cb) =>
      this.deleteComment(id, cb),
    
    updateResource: (resource, cb) =>
      this.updateResource(resource, cb),
    makeAdmin: (privateUserId) => this.makeAdmin(privateUserId),
    removeAdmin: (privateUserId) => this.removeAdmin(privateUserId),
    verifyUser: (privateUserId) => this.verifyUser(privateUserId),
    suspendUser: (privateUserId) => this.suspendUser(privateUserId),
    unsuspendUser: (privateUserId) => this.unsuspendUser(privateUserId),
    pinPost: (postId) => this.pinPost(postId),
    unpinPost: (postId) => this.unpinPost(postId),
    resetUserPassword: (user, cb) => this.resetUserPassword(user, cb),
  };

  componentDidMount() {
    this.setState({ database: this.props.db });
    this.props.auth.onAuthStateChanged((user) => {
      console.log("auth state change!!!")
      if (this.state.signingUp)
        return
      this.setState({ user: user, userLoaded: false });
      if (user) {
        console.log("user")
        console.log(user)
        if (!this.userPublicId) {
          this.setState({userPrivateId: user.uid});
          this.props.db.collection('privateUser').doc(user.uid).get().then(privateUser => {
            console.log("[FB-Context] privateUser", privateUser.data());
            if (privateUser) {
              this.setState({privateUser: privateUser.data()});
              this.setState({userPublicId: privateUser?.data()?.publicId});
              // Get publicUser
              this.props.db.collection('publicUser').doc(privateUser?.data()?.publicId).get().then(publicUser => {
                console.log("[FB-Context] publicUser", publicUser.data());
                this.setState({publicUser: publicUser.data()});
                this.setState({userLoaded: true});
              }).catch(this.props.fatalError);
            }
          }).catch(this.props.fatalError)
        }
      } else {
        // User logging out
        this.setState({ 
          userPublicId: null, 
          userPrivateId: null,
          publicUser:null, 
          privateUser: null,
          userLoaded: true
        });
      }
    });
  }

  userLogout() {
    this.props.auth
    .signOut().then(() => {
      // Sign-out successful.
    }).catch(this.props.fatalError);
  }

  loginEmailPassword(credentails, cb) {
    if(credentails.email === undefined || credentails.password === undefined) {
      cb({ error: new Error("Missing username or password") });
    }
    this.props.auth
      .signInWithEmailAndPassword(credentails.email, credentails.password)
      .then((res) => {
        cb({ error: false, message: "Successfully logged in" });
      })
      .catch((err) => {
        this.setState({userLoaded: true});
        if (err.code === "auth/user-not-found" || err.code === "auth/wrong-password") {
          cb({ error: new Error("This account either does not exist or you entered the wrong password.") });
        } else if (err.code === "auth/invalid-email") {
          cb({ error: new Error("Oh no, that doesn't look like an email address.") });
        } else {
          this.props.fatalError(err);
        }
      });
  }

  makeAdmin(privateUserId) {
    this.getPrivateUser(privateUserId).then(privateUser => {
      let privateUserData = privateUser.data()
      privateUserData.permissions.push('admin')
      this.updatePrivateUser(privateUserData, privateUserId)
    })
  }

  removeAdmin(privateUserId) {
    this.getPrivateUser(privateUserId).then(privateUser => {
      let privateUserData = privateUser.data()
      privateUserData.permissions = privateUserData.permissions.filter((permission) => {
        return permission !== "admin";
      })
      this.updatePrivateUser(privateUserData, privateUserId)
    })
  }

  verifyUser(privateUserId) {
    this.getPrivateUser(privateUserId).then(privateUser => {
      let privateUserData = privateUser.data()
      privateUserData.permissions.push('verified')
      this.updatePrivateUser(privateUserData, privateUserId)
    })
  }

  suspendUser(privateUserId) {
    this.getPrivateUser(privateUserId).then(privateUser => {
      let privateUserData = privateUser.data()
      privateUserData.permissions.push('suspended')
      this.updatePrivateUser(privateUserData, privateUserId)
    })
  }

  unsuspendUser(privateUserId) {
    this.getPrivateUser(privateUserId).then(privateUser => {
      let privateUserData = privateUser.data()
      privateUserData.permissions = privateUserData.permissions.filter((permission) => {
        return permission !== 'suspended'
      })
      this.updatePrivateUser(privateUserData, privateUserId)
    })
  }

  pinPost(postId) {
    this.getPost(postId).then(post => {
      let postData = post.data()
      postData.pinned = true
      this.updatePost(postData)
    })
  }

  unpinPost(postId) {
    this.getPost(postId).then(post => {
      let postData = post.data()
      postData.pinned = false
      this.updateComment(postData)
    })
  }

  resetUserPassword(user, cb) {
    if(user.email === undefined) {
      cb({ error: Error('missing email') });
    }
    this.props.auth.sendPasswordResetEmail(user.email).then(function() {
      cb({message: 'email to reset password sent!'})
    }).catch(function(error) {
      cb({ error: Error('email failed to send') });
    });
  }

  createAccountEmailPassword(user, cb) {
    if(!user.email || !user.password || user.email === "" || user.password === "") {
      cb({ error: Error('missing username or password') });
    }
    this.setState({signingUp: true})
    this.props.auth
      .createUserWithEmailAndPassword(user.email, user.password)
      .then((result) => {
        this.props.db.collection('publicUser').add({
          fname: user.fname ?? '',
          lname: user.lname ?? '',
          graduationYear: '',
          profilePic: user.profilePic ?? '',
          type: user.type ?? 'GT Excel Alumni',
          interests: user.interests ?? '',
          location: user.location ?? '',
          friendIds: [],
          postIds: [],
          commentIds: [],
          contactInfo: user.contactInfo ?? {
            facebook: '',
            twitter: '',
            email: user.email ?? '',
            phoneNumber: '',
          },
        }).then(publicUser => {
          console.log(`Created public user with id: ${publicUser.id}`)
          this.setState({userPublicId: publicUser.id});
          this.props.db.collection('privateUser').doc(result.user.uid).set({
            publicId: publicUser.id,
            age: user.age ?? null,
            permissions: user.permissions ?? ['default'],
            settings: {
              notifications: true
            },
            suggestedPostIds: []
          }).then(() => {
            let privateUserUpdate = new Promise(resolve => {
              this.props.db.collection('privateUser').doc(result.user.uid).get()
                .then(privateUser => {
                  this.setState({privateUser: privateUser.data(), userPrivateId: result.user.uid}, resolve);
                })
              })
            let publicUserUpdate = new Promise(resolve => {
              this.props.db.collection('publicUser').doc(publicUser.id).get()
                .then(publicUser => {
                  this.setState({publicUser: publicUser.data()}, resolve);
                })
            })
            Promise.all([privateUserUpdate, publicUserUpdate]).then(() => {
              this.setState({userLoaded: true, signingUp: false});
              cb();
            })
          }).catch(err => {
            console.log("[FB-Context] Error creating private user");
            console.log(err);
            cb({error: err});
            // this.props.fatalError("Error creating private user");
          })
        }).catch(err => {
          console.log("[FB-Context] Error creating public user");
          console.log(err);
          cb({error: err});
          // this.props.fatalError("Error creating public user");
        })
      })
      .catch((err) => {
        console.log("[FB-Context] Error creating account");
        console.log(err);
        cb({error: err});
        // this.props.fatalError("Error creating account");
      });
  }

  deleteAccountEmailPassword(id, cb) {
    this.props.db.collection('privateUser').doc(id).get().then(privateUser => {
      if(privateUser.publicId) {
        this.props.db.collection('publicUser').doc(privateUser.publicId).delete().catch((error) => {
          throw new Error('error deleting publicUser (id from private user doc)', error.message)
        })
      }
      this.props.db.collection('privateUser').doc(id).delete().catch((error) => {
        throw new Error('error deleting privateUser', error.message)
      })
    }).catch((error) => {
      throw new Error('error getting user with id', {id}, error.message)
    })
  }

  updatePublicUser(publicUser, id, cb) {
    // TODO check if user is logged in and correct user (maybe)
    if(id === undefined || id === '') {
      throw new Error('missing or empty uid');
    }
    this.props.db.collection('publicUser').doc(id).update(publicUser).then(() => {
      //TODO on public account updated
    }).catch((error) => {
      throw new Error('error updating publicUser', error.message)
    })
  }

  updatePrivateUser(privateUser, id, cb) {
    // TODO check if user is logged in and correct user
    if(id === undefined || id === '') {
      throw new Error('missing or empty uid')
    }
    this.props.db.collection('privateUser').doc(id).update(privateUser).then(() => {
      //TODO on private account updated
    }).catch((error) => {
      throw new Error('error updating privateUser', error.message)
    })
  }

  getPublicUser(id) {
    if (!id) {
      throw new Error('[getPublicUser] missing or empty id');
    }
    let publicUser = this.props.db.collection('publicUser').doc(id).get().catch(err => {
      throw new Error('could not get public user')
    })
    return publicUser
  }
  getPrivateUser(id) {
    if (!id) {
      throw new Error('[getPrivateUser] missing or empty id');
    }
    return new Promise((resolve) => {
      this.props.db.collection('privateUser').doc(id).get().then(privateUser => {
        resolve(privateUser)
      }).catch(err => {
        throw new Error('could not get private user')
      })
    })
   
  }

  getPost(id) {
    if (!id) {
      throw new Error('[getPost}] missing or empty id');
    }
    let post = this.props.db.collection('publicUser').doc(id).get().catch(err => {
      throw new Error('could not get private user')
    })
    return post;
  }

  createPost(post, cb) {
    if(post.title === undefined || post.title === '' || this.state.user.uid === undefined) {
      throw new Error('missing or empty post title or user not logged in')
    }
    var x = firebase.firestore.Timestamp.fromDate(new Date());
    this.props.db.collection('posts').add({
      title: post.title ?? '',
      authorId: this.state.userPublicId,
      createdAtDate: x.toDate().toDateString(),
      createdAtTime: x.toDate().toLocaleTimeString('en-US'),
      creDate: firebase.firestore.FieldValue.serverTimestamp(),
      likesProfileIds: [],
      commentIds: [],
      hasRSVP: post.hasRSVP ?? false,
      pinned: false,
      content: post.content ?? {
        text: 'empty post'
      },
      type: post.type ?? 'community'
    }).then(post => {
      this.props.db.collection('publicUser').doc(this.state.userPublicId).update({
        postIds: firebase.firestore.FieldValue.arrayUnion(post.id),
      }).catch((err) => {
        throw new Error('error adding post to publicUser model', err.message)
      })
    }).catch((err) => {
      throw new Error('error creating post', err.message)
    })
  }

  updatePost(post, id, cb) {
    if(id === undefined || id === '') {
      throw new Error('missing or empty post ID')
    }
    if (post.id)
      delete post.id
    if (post.authorRole)
      delete post.authorRole
    // Ensure all community posts have an rsvp list
    // Needed if post type is changed in update
    if (post.type !== "community" && post.content && post.content.rsvpList) {
      delete post.content.rsvpList;
    }
    if (post.type === "community" && post.content && !post.content.rsvpList) {
      post.content.rsvpList = [];
    }
    this.props.db.collection('posts').doc(id).update(post).then(() => {
      //TODO on post updated
    }).catch((error) => {
      console.log(error);
      throw new Error('error updating post', error.message)
    })
  }

  deletePost(id, cb) {
    // TODO check if user is logged in and correct user (maybe)
    this.props.db.collection('posts').doc(id).get().then(doc => {
      let promiseBasket = []
      doc.data().commentIds.forEach(commentId => {
        promiseBasket.push(this.props.db.collection('comments').doc(commentId).delete())
      })
      Promise.all(promiseBasket).then(() => {
        this.props.db.collection('posts').doc(id).delete().catch((error) => {
          throw new Error('error deleting post', error.message)
        })
      })
    })
  }

  createComment(comment, cb) {
    if(comment.content === undefined || comment.content === '' || comment.parentId === undefined || this.state.userPublicId === null) {
      throw new Error('missing or empty comment content or missing parentId')
    }
    var x = firebase.firestore.Timestamp.fromDate(new Date());
    this.props.db.collection('comments').add({
      authorId: this.state.userPublicId,
      createdAtDateC: x.toDate().toDateString(),
      createdAtTimeC: x.toDate().toLocaleTimeString('en-US'),
      creDate: firebase.firestore.FieldValue.serverTimestamp(),
      content: comment.content ?? null,
      parentId: comment.parentId,
      parentType: comment.parentType ?? 'post',
      childCommentIds: [],
    }).then(commentRef => {
      if(comment.parentType === 'comment') {
        this.props.db.collection('comments').doc(comment.parentId).update({
          childCommentIds: firebase.firestore.FieldValue.arrayUnion(commentRef.id),
        }).catch((err) => {
          throw new Error('error adding comment as child to comments model', err.message)
        })
      } else {
        this.props.db.collection('posts').doc(comment.parentId).update({
          commentIds: firebase.firestore.FieldValue.arrayUnion(commentRef.id),
        }).catch((err) => {
          console.log(err)
          throw new Error('error adding comment as child to posts model', err.message)
        })
      }
      this.props.db.collection('publicUser').doc(this.state.userPublicId).update({
        commentIds: firebase.firestore.FieldValue.arrayUnion(commentRef.id),
      }).catch((err) => {
        throw new Error('error adding comment to publicUser model', err.message)
      })
    }).catch((err) => {
      console.log(err.message)
      //throw new Error('error creating comment', err.message)
    })
  }

  updateComment(comment, id, cb) {
    // TODO check if user is logged in and correct user (maybe)
    if(id === undefined || id === '') {
      throw new Error('missing or empty comment ID')
    }
    this.props.db.collection('comments').doc(id).update(comment).then(() => {
      //TODO on comment updated
    }).catch((error) => {
      throw new Error('error updating comment', error.message)
    })
  }

  deleteComment(id, cb) {
    // TODO check if user is logged in and correct user (maybe)
    this.props.db.collection('comments').doc(id).delete().catch((error) => {
      throw new Error('error deleting comment', error.message)
    })
  }

  updateResource(resource, id, cb) {
    if(!this.state.user.uid || !id) {
      throw new Error('missing resource id or user not logged in');
    }
    this.props.db.collection('resources').doc(id).update(resource).then(() => {
      if (cb) { cb() }
    }).catch(error => {
      throw new Error('error updating resource', error.message);
    })
  }

  render() {
    return (
      <FirebaseContext.Provider value={{ ...this.state }}>
        {this.state.database && this.props.children} 
      </FirebaseContext.Provider>
    );
  }
}

// Make this sucker a HOC <('-'<)
function withErrorHandler() {
  return function WrappedComponent(props) {
    const fatalError = useFatalError();
    return <FirebaseContextProvider fatalError={fatalError} {...props} />
  };
}

export default withErrorHandler();
