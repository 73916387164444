import React, { useContext, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
} from "@material-ui/core";
import RichTextEditor from "react-rte";
import ReactHtmlParser from 'react-html-parser';
import { FirebaseContext } from "../../contexts/FirebaseContext";
import "./RichTextCard.scss";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";


const RichTextCard = (props) => {
  const { type, resource } = props;
  const context = useContext(FirebaseContext);
  const [text, setText] = useState(RichTextEditor.createValueFromString(resource.content.html, 'html'));
  const [edit, setEdit] = useState(false);

  const onChange = (value) => {
    setText(value);
  };

  const submit = () => {
    let cb = err => {
      console.log(err);
    }
    const r = {
      content: {
        html: text.toString('html')
      },
      type: props.type
    }
    context.updateResource(r, resource.id, cb);
  }

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  };

  const colors = createMuiTheme({
    palette: {
      primary: {
        // Dark Blue 
        main:'#003057',
      },
    }
  });

  return (
    <ThemeProvider theme={colors}>
    <Card>
      {edit && 
        <div>
          <CardContent>
            <RichTextEditor toolbarConfig={toolbarConfig} value={text} onChange={onChange} />
          </CardContent>
        </div>
      }
      {!edit && 
        <div>
          <CardHeader title={`${type.charAt(0).toUpperCase() + type.slice(1)} Resources`} />
          <CardContent>
            {ReactHtmlParser(text.toString('html'))}
          </CardContent>
        </div>
      }
      <CardActions className="actions">
        {context.privateUser.permissions.includes("admin") ?
        <Button onClick={() => {
          if (edit)
            submit()
          setEdit(!edit);
        }}
           variant="contained"
           color="primary"
           size="large"
        >
          {edit && "Save"}
          {!edit && "Edit"}
        </Button>: 
        null
        }
      </CardActions>
    </Card>
    </ThemeProvider>
  );
};

export default RichTextCard;
