import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ButtonGroup, createMuiTheme, ThemeProvider } from "@material-ui/core";
import "./CreatePost.scss";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
const EditPost = (props) => {

  const [post, setPost] = useState({});
  const [flag, setFlag] = React.useState(true);


  const context = useContext(FirebaseContext);

  useEffect(() => {
    if (props.id) {
    context.database
      .collection("posts")
      .doc(props.id)
      .get()
      .then((postDoc)=> {
        setPost(postDoc.data());
      })
    }
  }, [context.database, props.id]);

  const handleClose = () => {
    props.setClose();
  };

  const handlePost = () => {
    let cb
    context.updatePost(post, props.id, cb);
    props.setClose();
  };

  const setType = (type) => {
      post.type = type;
      setPost(post);
  }

  const setTitle = (title) => {
      post.title = title;
      setPost(post);
  }

  const setDesc = (desc) => {
      post.content.text = desc;
      setPost(post);
  }

  const handleClick = () => {
    setFlag(!flag);
  };

  const colors = createMuiTheme({
    palette: {
      primary: {
        // Dark Blue 
        main:'#003057',
      },
      secondary: {
        // black
        main:'#000',
      },
    }
  });


  return (
    <ThemeProvider theme={colors}>
    <div>
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Post</DialogTitle>
        <DialogContent>
        <div className="type-selector-container">
          <ButtonGroup className="type-selector" size="large" color="primary">
            <Button variant={post?.type && post.type === "community" && "contained"} onClick={() => {handleClick(); setType("community")}} color={flag ? "primary" : "primary"}>Community</Button>
            <Button variant={post?.type && post.type === "career" && "contained"} onClick={() => {handleClick(); setType("career")}} color={flag ? "primary" : "primary"}>Career</Button>
          </ButtonGroup>
        </div>
        <div className="type-selector-container">

        {post.type === "community" ? 
          <div>
          <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="top"
          control={<Checkbox onChange={() => post.hasRSVP=!post.hasRSVP} color="primary" />}
          label={post.hasRSVP? "Disable RSVP" : "Enable RSVP"}
          labelPlacement="end"
        />
        </FormGroup>
        </FormControl>
        </div>
          : 
          null}
          </div>
          <TextField
            className="input-title"
            defaultValue={post.title}
            type="text"
            variant="outlined"
            onChange={(event) => setTitle(event.target.value)}
            fullWidth
          />
          <TextField
            defaultValue={post?.content?.text}
            multiline
            rows={5}
            variant="outlined"
            onChange={(event) => setDesc(event.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handlePost} color="secondary" variant="outlined">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </ThemeProvider>
  );
};

EditPost.propTypes = {
  type: PropTypes.string,
};

export default EditPost;
