import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary"
import PublicRoute from "./PublicRoute";
import SecureRoute from "./SecureRoute";
import FirebaseContextProvider from "./contexts/FirebaseContext";
import Login from "./views/Login/Login";
import View from "./views/View/View";
import CreateAccount from "./views/Login/CreateAccount";

const App = (props) => {
  return (
    <ErrorBoundary>
      <FirebaseContextProvider auth={props.auth} db={props.db}>
        <Router>
          <Switch>
            <PublicRoute path="/login" exact component={Login} title="Login" />
            <SecureRoute path="/feed/:feedType" exact component={View} viewType="feed" title="Feed" />
            <SecureRoute path="/resources/:resourceType" exact component={View} viewType="resources" title="Resources" />
            <PublicRoute path="/signup" exact component={CreateAccount} title="Create Account" />
            <SecureRoute path="/profile" exact component={View} viewType="profile" title="Profile" />
            <SecureRoute path="/directory" exact component={View} viewType="directory" title="Directory" />
            <SecureRoute path="/admin" exact component={View} viewType="admin" title="Admin Page" adminOnly />
            <SecureRoute
              path="/feed"
              exact
              component={() => <Redirect to="/feed/community" />}
              title="Feed"
            />
            {/* Catch any routes that don't match */}
            <Route path="*" exact component={() => <Redirect to="/login" />} />
          </Switch>
        </Router>
      </FirebaseContextProvider>
    </ErrorBoundary>
  );
};
export default App;
