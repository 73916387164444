import React, { useEffect, useState, useContext } from 'react'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { withRouter } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import { Container, CssBaseline } from '@material-ui/core'

import './View.scss'
import Sidebar from './Sidebar'
import Feed from '../../components/Feed/Feed'
import Profile from '../../components/Profile/Profile.jsx'
import Resource from '../Resource/Resource.jsx'
import Directory from '../../components/Directory/Directory.jsx'
import Admin from '../../components/Admin/Admin.jsx'
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert.jsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  errorAlertContainer: {
    paddingBottom: theme.spacing(3)
  },
}))

const View = withRouter((props) => {
  const context = useContext(FirebaseContext)
  // Pull params from url
  const { feedType, resourceType } = useParams()
  const classes = useStyles()
  const { history } = props
  const [viewType, setViewType] = useState(props.viewType)

  useEffect(() => {
    setViewType(props.viewType)
  }, [props.viewType])

  console.log(`[View] viewType=${viewType} | feedType=${feedType} | resourceType=${resourceType}`)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Sidebar viewType={viewType} feedType={feedType} resourceType={resourceType} history={history} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container>
          {context.privateUser &&
            context.privateUser.permissions &&
            context.privateUser.permissions.includes('suspended') && (
              <div className={classes.errorAlertContainer}>
                <ErrorAlert
                  error={
                    new Error('Your account is currently suspended. Please ask an admin to reactivate your account.')
                  }
                  severity="warning"
                />
              </div>
            )}
          {context.privateUser &&
            context.privateUser.permissions &&
            !context.privateUser.permissions.includes('verified') && (
              <div className={classes.errorAlertContainer}>
                <ErrorAlert
                  error={
                    new Error('Your account is currently under review. Please ask an admin to verify your account.')
                  }
                  severity="warning"
                />
              </div>
            )}

          {viewType === 'feed' && <Feed type={feedType} />}
          {viewType === 'resources' && <Resource type={resourceType} />}
          {viewType === 'profile' && <Profile />}
          {viewType === 'directory' && <Directory />}
          {viewType === 'admin' && <Admin />}
        </Container>
      </main>
    </div>
  )
})

View.propTypes = {
  children: PropTypes.object,
  history: PropTypes.object.isRequired,
}

export default View
