import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./EventRsvp.scss";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import EventIcon from "@material-ui/icons/Event";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const EventRsvp = (props) => {
  const context = useContext(FirebaseContext);
  const [open, setOpen] = useState(false);
  const [rsvpNames, setRsvpNames] = useState([]);
  const { post } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRSVP = () => {
    if (post.content.rsvpList.includes(context.userPublicId))
      throw new Error("User is already RSVP'd for this event");
    post.content.rsvpList.push(context.userPublicId);
    let cb;
    context.updatePost(post, post.id, cb);
    setOpen(false);
  }

  const handleUnRSVP = () => {
    post.content.rsvpList = post.content.rsvpList.filter((id) => {
      return id !== context.userPublicId
    })
    let cb;
    context.updatePost(post, post.id, cb);
    setOpen(false);
  }

  const colors = createMuiTheme({
    palette: {
      primary: {
        // Dark Blue 
        main:'#003057',
      },
      secondary: {
        // black
        main:'#000',
      },
    }
  });

  useEffect(() => {
    if (!post || !post.content || !post.content.rsvpList) {
      console.log("[EventRsvp] Error: missing post or post content rsvpList");
      setRsvpNames([]);
      return;
    }
    async function fetchData() {
      let promiseBucket = [];
      post.content.rsvpList.forEach(userPublicId => {
        promiseBucket.push(context.getPublicUser(userPublicId))
      });
      let rsvpListNames = [];
      Promise.all(promiseBucket).then(publicUsers => {
        publicUsers.forEach(publicUser => {
          let user = publicUser.data();
          rsvpListNames.push(`${user.fname} ${user.lname}`);
        });
        setRsvpNames(rsvpListNames);
      });
    }
    fetchData();
  }, [context, post]);

  let renderRSVPButton = (
    <Button onClick={handleRSVP} color="secondary" variant="outlined">
        Confirm RSVP
    </Button>
  )  

  if(post.content.rsvpList.includes(context.userPublicId)) (
    renderRSVPButton = (
      <Button onClick={handleUnRSVP} color="secondary" variant="outlined">
          Remove RSVP
      </Button>
    )  
  )
  return (
    <ThemeProvider theme={colors}>
    <div>
        <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<EventIcon />}
            onClick={handleClickOpen}
        >
            RSVP
        </Button>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            maxWidth="sm"
        >
        <DialogTitle id="alert-dialog-slide-title">RSVP List</DialogTitle>
        <DialogContent>
          { (!rsvpNames || rsvpNames.length < 1) && <p>No RSVPs yet.</p> }
          { (rsvpNames && rsvpNames.length > 0) && 
          <ul>
            {rsvpNames.map((name, i) => <li key={i}> {name} </li>)}
          </ul>
          }
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
            </Button>
            {renderRSVPButton}
        </DialogActions>
        </Dialog>
    </div>
    </ThemeProvider>
  );
};

EventRsvp.propTypes = {
  post: PropTypes.object.isRequired
}

export default EventRsvp;
