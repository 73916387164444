import React from "react";
import PropTypes from "prop-types";
import Alert from '@material-ui/lab/Alert';

const ErrorAlert = props => {
  let message = "Unknown error occurred";
  if (props.error && props.error.message) {
    if (!props.severity || props.severity === 'error') {
      message = `Error: ${props.error.message}`;
    } else {
      message = props.error.message
    }
  }
  return (
  <Alert variant="filled" severity={props.severity ?? "error"}>
    {message}
  </Alert>);
}

ErrorAlert.propTypes = {
  error: PropTypes.object.isRequired,
  severity: PropTypes.string,
};

export default ErrorAlert;
