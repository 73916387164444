import React, { useEffect, useContext, useState } from 'react'
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import './Admin.scss'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { FirebaseContext } from '../../contexts/FirebaseContext'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { CSVLink } from 'react-csv'
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
  controls: {
    alignItems: 'center',
    margin: 'auto',
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  root: {
    justifyContent: 'center',
  },
  other: {
    alignItems: 'left',
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paper2: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  flexContainer: {
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardCenter: {
    padding: 1,
    margin: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardBottom: {
    marginBottom: '2',
  },
  searchFacets: {
    padding: 10,
  },
}))

const colors = createMuiTheme({
  palette: {
    primary: {
      main: '#003057',
    },
    secondary: {
      main: '#b3a369',
    },
  },
})

const textColors = createMuiTheme({
  palette: {
    primary: {
      // Black
      main: '#000',
    },
    secondary: {
      // Dark Blue for Make CSV button
      main: '#003057',
    },
  },
})

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const DirectoryCard = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [confirmModel, setConfirmModel] = useState(false)
  const [modalStyle] = React.useState(getModalStyle);
  const context = useContext(FirebaseContext)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleVerify = (id) => {
    context.verifyUser(props.privateID)
  }

  const handleReject = () => {
    context.suspendUser(props.privateID)
  }

  const handleAdmin = () => {
    context.makeAdmin(props.privateID)
  }

  const handleUnSuspend = () => {
    context.unsuspendUser(props.privateID)
  }

  const handleResetPassword = () => {
    function onDone(event) {
      if(event.error == null) {
        setConfirmModel(false)
      }
    }
    let user = {
      email: props.email
    }
    context.resetUserPassword(user, onDone) 
  }

  const handleConfirmModel = () => {
    setConfirmModel(true)
  }

  const unAdmin = () => {
    context.removeAdmin(props.privateID)
  }

  let makeAdminButton = (
    <Button variant="contained" color="primary" size="size" onClick={handleAdmin}>
      Make Admin
    </Button>
  )
  let verifyButton = (
    <Button variant="contained" color="primary" size="size" onClick={handleVerify}>
      Verify
    </Button>
  )
  let suspendButton = (
    <Button variant="contained" color="primary" size="size" onClick={handleReject}>
      Suspend
    </Button>
  )
  let resetPasswordButton = (
    <Button variant="contained" color="primary" size="size" onClick={handleConfirmModel}>
      Reset Password
    </Button>
  )
  if (props.permissions) {
    if (props.permissions.includes('admin')) {
      makeAdminButton = (
        <Button variant="contained" color="primary" size="size" onClick={unAdmin}>
          Remove Admin
        </Button>
      )
    }

    if (props.permissions.includes('verified')) {
      verifyButton = (
        <Button disabled variant="contained" color="primary" size="size" onClick={handleVerify}>
          Verify
        </Button>
      )
    }

    if (props.permissions.includes('suspended')) {
      suspendButton = (
        <Button variant="contained" color="primary" size="size" onClick={handleUnSuspend}>
          Un-suspend
        </Button>
      )
    }
  }


  const body = (
    <div style={modalStyle} className={classes.paper2}>
      <h2 >Are you sure you want to do this?</h2>
      <p >
        This will send an email to the user prompting them to create a new password
      </p>
      <Button variant="contained" color="secondary" size="size" onClick={handleResetPassword}>
          Reset Password
      </Button>
    </div>
  );
  return (
    <ThemeProvider theme={colors}>
      <Grid>
        <CardContent>
          <div class={classes.cardCenter}>
            <Button variant="outlined" onClick={handleClickOpen}>
              <Typography variant="body1" component="p">
                {props.fname} {props.lname}
              </Typography>
            </Button>
            <Dialog onClose={handleClose} aria-labelledby="Profile" open={open}>
              <DialogTitle alignItems="center" id="form-dialog-title">
                {props.fname} {props.lname}
              </DialogTitle>

              <DialogContent>
                <Typography variant="body1" component="p">
                  Email: {props.email}
                </Typography>
                <Typography variant="body1" component="p">
                  Phone Number: {props.phoneNumber}
                </Typography>
                <Typography variant="body1" component="p">
                  Facebook: {props.facebook}
                </Typography>
                <Typography variant="body1" component="p">
                  Twitter: {props.twitter}
                </Typography>
                <Typography variant="body1" component="p">
                  City, State: {props.location}
                </Typography>
                <Typography variant="body1" component="p">
                  Interests: {props.interests}
                </Typography>
                {verifyButton} {suspendButton} {makeAdminButton} {resetPasswordButton}
              </DialogContent>
              <Modal
                  open={confirmModel}
                  onClose={() => {setConfirmModel(false)}}
                >
                  {body}
              </Modal>
            </Dialog>
          </div>
        </CardContent>
      </Grid>
    </ThemeProvider>
  )
}

const Admin = () => {
  const classes = useStyles()

  const [names, setNames] = useState(false)
  const [csvData, setCsvData] = useState(null)
  const context = useContext(FirebaseContext)

  useEffect(() => {
    context.database.collection('privateUser').onSnapshot(async (snapshot) => {
      let nameList = []
      let promiseBucket = []
      snapshot.docs.forEach((privateUser) => {
        let getUser = context.database
          .collection('publicUser')
          .doc(privateUser.data().publicId)
          .get()
          .then((publicUserRaw) => {
            nameList.push({
              ...publicUserRaw.data(),
              id: publicUserRaw.id,
              privateID: privateUser.id,
              permissions: privateUser.data().permissions,
            })
          })
        promiseBucket.push(getUser)
      })
      await Promise.all(promiseBucket)
      setNames(nameList)
    })

    context.database
      .collection('privateUser')
      .get()
      .then((privateUsers) => {
        let list = [['fname', 'lname', 'city, state', 'email']]
        let promiseBucket = []
        privateUsers.forEach((privateDoc) => {
          let promise = new Promise((resolve) => {
            context.database
              .collection('publicUser')
              .doc(privateDoc.data().publicId)
              .get()
              .then((publicDoc) => {
                list.push([
                  publicDoc.data().fname,
                  publicDoc.data().lname,
                  publicDoc.data().location,
                  publicDoc.data()?.contactInfo?.email ?? publicDoc.data()?.email ?? '',
                ])
                resolve()
              })
          })
          promiseBucket.push(promise)
        })
        Promise.all(promiseBucket).then(() => {
          setCsvData(list)
        })
      })
  }, [context.database])

  let renderName
  if (names) {
    renderName = names?.filter((user) => {
      return !user.permissions.includes('verified') && !user.permissions.includes('suspended')
    })
  }
  let suspendedNames
  if (names) {
    suspendedNames = names?.filter((user) => {
      return user.permissions.includes('suspended')
    })
  }

  let allAdmins
  if (names) {
    allAdmins = names?.filter((user) => {
      return user.permissions.includes('admin') && !user.permissions.includes('suspended')
    })
  }

  return (
    <ThemeProvider theme={textColors}>
      <div>
        <div className={classes.controls}>
          <Card>
            <CardContent>
              <div class={classes.flexContainer}>{/* <MenuBookIcon style={{ fontSize: 50 }}/> */}</div>
              <Typography variant="h3" color="primary" align="center">
                Admin Page
              </Typography>
            </CardContent>
          </Card>
          <div className="space">
            <Card>
              <CardContent>
                <div className="no-card-text">
                  {csvData && <CSVLink data={csvData}>Export user information (CSV file)</CSVLink>}
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="space">
            <Card>
              <CardContent>
                <div className="Filter">
                  <Typography variant="h5" color="primary" align="center">
                    Admin List
                  </Typography>
                  {allAdmins && allAdmins.length < 1 ? <p className="no-card-text">No admins right now.</p> : null}

                  {allAdmins &&
                    allAdmins.length > 0 &&
                    allAdmins.map((user, i) => {
                      return (
                        <DirectoryCard
                          key={i}
                          fname={user.fname}
                          lname={user.lname}
                          email={user.contactInfo.email}
                          facebook={user.contactInfo.facebook}
                          twitter={user.contactInfo.twitter}
                          phoneNumber={user.contactInfo.phoneNumber}
                          location={user.location}
                          interests={user.interests}
                          id={user.id}
                          privateID={user.privateID}
                          permissions={user.permissions}
                        />
                      )
                    })}
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="space">
            <Card>
              <CardContent>
                <div className="Filter">
                  <Typography variant="h5" color="primary" align="center">
                    Profiles to Verify
                  </Typography>
                  {renderName && renderName.length < 1 ? <p className="no-card-text">No profiles to verfiy!</p> : null}

                  {renderName &&
                    renderName.length > 0 &&
                    renderName.map((user, i) => {
                      return (
                        <DirectoryCard
                          key={i}
                          fname={user.fname}
                          lname={user.lname}
                          email={user.contactInfo.email}
                          facebook={user.contactInfo.facebook}
                          twitter={user.contactInfo.twitter}
                          phoneNumber={user.contactInfo.phoneNumber}
                          location={user.location}
                          interests={user.interests}
                          id={user.id}
                          privateID={user.privateID}
                          permissions={user.permissions}
                        />
                      )
                    })}
                </div>
              </CardContent>
            </Card>
          </div>
          <Card>
            <CardContent>
              <Typography variant="h5" color="primary" align="center">
                Suspended Users
              </Typography>
              {suspendedNames && suspendedNames.length < 1 ? <p className="no-card-text">No suspended users</p> : null}

              {suspendedNames &&
                suspendedNames.length > 0 &&
                suspendedNames.map((user, i) => {
                  return (
                    <DirectoryCard
                      key={i}
                      fname={user.fname}
                      lname={user.lname}
                      email={user.contactInfo.email}
                      facebook={user.contactInfo.facebook}
                      twitter={user.contactInfo.twitter}
                      phoneNumber={user.contactInfo.phoneNumber}
                      location={user.location}
                      interests={user.interests}
                      id={user.id}
                      privateID={user.privateID}
                      permissions={user.permissions}
                    />
                  )
                })}
            </CardContent>
          </Card>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Admin
