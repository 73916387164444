import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Fab, ButtonGroup } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import "./CreatePost.scss";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const CreatePost = (props) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [type, setType] = useState(props.type ?? 'community');
  const context = useContext(FirebaseContext);
  const [rsvpBut, setRSVPbut] = useState(props.hasRSVP);

  useEffect(() => {
    setType(props.type);
  }, [props.type]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePost = () => {
    let post = {
      title: title,
      fname: context.publicUser.fname,
      lname: context.publicUser.lname,
      createdAt: context.createdAt,
      likes: 0,
      likesProfileIds: [],
      commentIds: [],
      hasRSVP: rsvpBut,
      content: {
        text: desc
      },
      type: type
    }
    if (type === "community") {
      post.content.rsvpList = [];
    }
    let cb
    context.createPost(post, cb);
    setOpen(false);
  };

  const colors = createMuiTheme({
    palette: {
      primary: {
        // Dark Blue 
        main:'#003057',
      },
      secondary: {
        // black
        main:'#000',
      },
    }
  });

  return (
    <ThemeProvider theme={colors}>
    <div>
      <Fab
        justifyContent="center"
        className="add-button"
        variant="extended"
        aria-label="add"
        color="primary" 
        onClick={handleClickOpen}
      >
        <AddIcon />
        {props.showButtonText && "Create post"}
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create post</DialogTitle>
        <DialogContent>
        <div className="type-selector-container">
          <ButtonGroup className="type-selector" size="large" color="primary">
            <Button variant={type && type === "community" && "contained"} onClick={() => setType("community")} color="primary">Community</Button>
            <Button variant={type && type === "career" && "contained"} onClick={() => setType("career")} color="primary">Career</Button>
          </ButtonGroup>
          </div>
          <div className="type-selector-container">
          {type === "community" ? 
          <div>
          <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="top"
          control={<Checkbox  onChange={() => setRSVPbut(true)} color="primary" />}
          label="Enable RSVP"
          labelPlacement="end"
        />
        </FormGroup>
        </FormControl>
        </div>
          : 
          null}

        </div>
         
          <TextField
            className="input-title"
            label="Title"
            type="text"
            variant="outlined"
            onChange={(event) => setTitle(event.target.value)}
            fullWidth
          />
          <TextField
            label="Description"
            multiline
            rows={5}
            variant="outlined"
            onChange={(event) => setDesc(event.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePost} variant="outlined" color="secondary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </ThemeProvider>
  );
};

CreatePost.propTypes = {
  showButtonText: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

export default CreatePost;
