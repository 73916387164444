import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

const PublicRoute = (props) => {
  const { component: Component, ...rest } = props;
  document.title = `${props.title || "Excel Alumni Portal"}`;
  return (
    <Route
      exact
      {...rest}
      render={(props) => <Component {...rest} {...props} />}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default PublicRoute;
