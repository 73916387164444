import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import clsx from "clsx";
import { FirebaseContext } from "../../contexts/FirebaseContext";

import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PeopleIcon from "@material-ui/icons/People";
import WorkIcon from "@material-ui/icons/Work";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuIcon from "@material-ui/icons/Menu";

import CreatePost from "../../components/CreatePost/CreatePost";
import "./Sidebar.scss";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';
import NoteIcon from '@material-ui/icons/Note';
import HomeIcon from '@material-ui/icons/Home';
import FolderSharedIcon from '@material-ui/icons/FolderShared';


const drawerWidth = 240;

const colors = createMuiTheme({
  palette: {
    primary: {
      // Dark Blue 
      main:'#003057'
    },
    secondary: {
      // Admin Tab: Tech Gold
      main:'#b3a369',
    },
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  expandButton: {
    marginRight: 1,
    marginLeft: 1,
    padding: 0.1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  
  fabContainer: {
    marginTop: 20,
    justifyContent: 'center',
  },
  right: {
    marginLeft: "auto",
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const { viewType, feedType, resourceType } = props;
  const context = useContext(FirebaseContext);
  const [flag, setFlag] = React.useState(true);
  const [flag1, setFlag1] = React.useState(true);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickCom = () => {
    setFlag(!flag);
  };

  const handleClickCar = () => {
    setFlag1(!flag1);
  };

  const handleLogout = () => {
    context.userLogout();
  };



  return (
    <ThemeProvider theme={colors}>
    <div className={classes.root}>
      <AppBar
        color="primary"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap >
            Excel Alumni Portal
          </Typography>
          <Button 
            variant="outlined" 
            onClick={handleLogout}
            color="inherit"
            className={classes.right}>
            Logout
          </Button>

        </Toolbar> 
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div className={classes.fabContainer}>
          <CreatePost showButtonText={open} type={feedType || "community"} />
        </div>
        <Divider />
        <List>
          <ListItem
            selected={viewType === "feed" && feedType === "community"}
            onClick={() => {props.history.push("/feed/community");}}
            button
            key={0}
          >
            <ListItemIcon>
              {" "}
              <PeopleIcon />{" "} 
            </ListItemIcon>
            <ListItemText primary="Community" />
            <IconButton onClick={() => handleClickCom()} variant="outlined" size="small">{flag ? <ExpandMore/> : <ExpandLess/>}</IconButton>
          </ListItem>
          {flag ?  
          <List>
          <ListItem
          selected={viewType === "feed" && feedType === "community"}
          onClick={() => props.history.push("/feed/community")}
          button
          key={"CommunityFeed"}
          ><ListItemIcon>
          {" "}
          <NoteIcon />{" "} 
        </ListItemIcon>
        <ListItemText primary="Feed" />
          </ListItem>
          <ListItem
          selected={viewType === "resources" && resourceType === "housing"}
          // PUT REAL LINK TO RESOURCES UNDER THIS LINK
          onClick={() => props.history.push("/resources/housing")}
          button
          key={"Housing"}
          ><ListItemIcon>
          {" "}
          <HomeIcon />{" "} 
        </ListItemIcon>
        <ListItemText primary="Housing" />
          </ListItem>

          </List>

          : null }

          <ListItem
            selected={viewType === "feed" && feedType === "career"}
            onClick={() => props.history.push("/feed/career")}
            button
            key={"CareerFeed"}
          >
            <ListItemIcon>
              {" "}
              <WorkIcon />{" "}
            </ListItemIcon>
            <ListItemText primary="Career" />{" "}
            <IconButton  onClick={() => handleClickCar()} variant="outlined" size="small">{flag1 ? <ExpandMore/> : <ExpandLess/>}</IconButton>
          </ListItem>
          {flag1 ?  
          <List>
          <ListItem
          selected={viewType === "feed" && feedType === "career"}
          onClick={() => props.history.push("/feed/career")}
          button
          key={1}
          ><ListItemIcon>
          {" "}
          <NoteIcon />{" "} 
        </ListItemIcon>
        <ListItemText primary="Feed" />
          </ListItem>

          <ListItem
          selected={viewType === "resources" && resourceType === "career"}
          onClick={() => props.history.push("/resources/career")}
          button
          key={"Resources"}
          ><ListItemIcon>
          {" "}
          <HelpIcon />{" "} 
        </ListItemIcon>
        <ListItemText primary="Resources" />
          </ListItem>
          </List>

          
          
          : null }
        </List>
        <Divider />
        <List>
          <ListItem 
            selected={viewType === "profile"}
            onClick={() => props.history.push("/profile")}
            button
            key={3}>
            <ListItemIcon>
              {" "}
              <AccountCircleIcon />{" "}
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>

          <ListItem 
            selected={viewType === "directory"}
            onClick={() => props.history.push("/directory")}
            button
            key={4}>
            <ListItemIcon>
              {" "}
              <FolderSharedIcon />{" "}
            </ListItemIcon>
            <ListItemText primary="Directory" />
          </ListItem>
        {context.privateUser.permissions.includes("admin") ? 
          <ListItem button key={5}
          style={{backgroundColor:"#b3a369"}}
          selected={viewType === "admin"}
          onClick={() => props.history.push("/admin")}>
            <ListItemIcon>
              {" "}
              <SettingsIcon />{" "}
            </ListItemIcon>
            <ListItemText primary="Admin Page"/>
          </ListItem>:null
        }
        </List>
      </Drawer>
    </div>
  </ThemeProvider>
  );
};

Sidebar.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Sidebar;
