import React from "react";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import { Container } from "@material-ui/core";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("[ErrorBoundary] Caught a fatal error (see below for debugging info)");
    console.log(error);
    console.log(errorInfo);
    this.setState({error: error});
  }

  render() {
    if (this.state.error) {
      return (
        <Container>
          <ErrorAlert error={this.state.error} />
        </Container>);
    } else {
      return this.props.children;
    }
  }
}

function useFatalError() {
  const [error, setError] = React.useState(null);
  if (error) throw error;
  return setError;
}

export { useFatalError };
export default ErrorBoundary;
