import React, { useEffect, useContext } from "react";
import { CircularProgress } from "@material-ui/core";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import RichTextCard from "../../components/RichTextCard/RichTextCard";

const Resource = props => {
  const [resource, setResource] = React.useState(null);
  const context = useContext(FirebaseContext);

  useEffect(() => {
    setResource(null);
    context.database
      .collection("resources")
      .onSnapshot((snapshot) => {
        snapshot.docs.forEach((resource) => {
          if(resource.data().type === props.type) {
            setResource({...resource.data(), id: resource.id});
          }
        });
      });
  }, [context.database, props.type]);

  return (<div>
      {resource && props.type && <RichTextCard resource={resource} type={props.type} title="Resources" />}
      {(!resource || !props.type) && 
        <div className="loader">
          <CircularProgress />
        </div>
      }
    </div>);
};

export default Resource;
