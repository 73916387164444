import React, { useEffect, useContext } from 'react'
import { FirebaseContext } from '../../contexts/FirebaseContext'
import {
  Card,
  Typography,
  CardContent,
  CardActions,
  CardHeader,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Divider,
  Box,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core'

import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import CreateReply from '../CreateReply/CreateReply'
import EventRsvp from '../EventRsvp/EventRsvp'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditPost from '../../components/CreatePost/EditPost'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import './CommunityCard.scss'
import Chip from '@material-ui/core/Chip'

const CommunityCard = (props) => {
  const context = useContext(FirebaseContext)
  const { post } = props
  const [postAuthor, setPostAuthor] = React.useState(false)
  const [comments, setComments] = React.useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseDelete = () => {
    context.deletePost(post.id)
    setAnchorEl(null)
  }

  const handlePinClose = () => {
    post.pinned = true
    let cb
    context.updatePost(post, post.id, cb)
    setAnchorEl(null)
  }

  const handleUnPinClose = () => {
    post.pinned = false
    let cb
    context.updatePost(post, post.id, cb)
    setAnchorEl(null)
  }

  const handleClosePost = () => {
    setOpen(false)
  }

  const onClickLike = () => {
    let cb
    post.likesProfileIds.push(context.userPublicId)
    context.updatePost(post, post.id, cb)
  }

  const onClickUnlike = () => {
    let cb
    post.likesProfileIds = post.likesProfileIds.filter((id) => {
      return id !== context.userPublicId
    })
    context.updatePost(post, post.id, cb)
  }

  useEffect(() => {
    async function fetchData() {
      context.getPublicUser(post.authorId).then((publicUser) => {
        let user = publicUser.data()
        user.id = post.authorId
        setPostAuthor(user)
      })
    }
    fetchData()
  }, [context, context.database, post.id, post.authorId])

  const colors = createMuiTheme({
    palette: {
      primary: {
        // Dark Blue
        main: '#003057',
      },
      secondary: {
        // black
        main: '#000',
      },
    },
  })
  useEffect(() => {
    async function fetchData() {
      if (post.id) {
        await context.database
          .collection('comments')
          .where('parentId', '==', post.id)
          .onSnapshot(async (snapshot) => {
            if (snapshot) {
              const newComments = []
              for (let i = 0; i < snapshot.docs.length; i++) {
                let commentId = snapshot.docs[i].id
                let currentDoc = snapshot.docs[i].data()
                const userSnapshot = await context.database.collection('publicUser').doc(currentDoc.authorId).get()
                let commentAuthor = userSnapshot.data()
                let commentAuthorName = `${commentAuthor.fname} ${commentAuthor.lname}`
                newComments.push({ ...currentDoc, id: commentId, author: commentAuthorName })
              }
              newComments.sort((a, b) => {
                if (a.creDate === b.creDate) return 0
                return a.creDate > b.creDate ? -1 : 1
              })
              setComments(newComments)
            }
          })
      }
    }
    fetchData()
  }, [context.database, post.id])


  let renderLikeButton =(
    <Button
      variant="contained"
      color="primary"
      size="large"
      disabled={post.authorId === context.userPublicId}
      startIcon={<ThumbUpIcon />}
      onClick={onClickLike}
    >            
      {post.likesProfileIds.length} {post.likesProfileIds.length === 1 ? 'Like' : 'Likes'}
    </Button>
    )
    if(post.likesProfileIds.includes(context.userPublicId)) {
      renderLikeButton =(
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={post.authorId === context.userPublicId}
          startIcon={<ThumbDownIcon />}
          onClick={onClickUnlike}
        >            
          {post.likesProfileIds.length} {post.likesProfileIds.length === 1 ? 'Like' : 'Likes'}
        </Button>
      )
    }

  return (
    <ThemeProvider theme={colors}>
      <Card className="community-card">
        <CardHeader
          avatar={
            postAuthor && (
              <Avatar alt="0" aria-label="personIcon" className={'avatar'}>
                {postAuthor.fname.charAt(0) + postAuthor.lname.charAt(0)}
              </Avatar>
            )
          }
          titleTypographyProps={{ variant: 'h5' }}
          title={post.title}
          subheader={
            postAuthor && (
              <div>
                <Chip label={postAuthor.type} size="small" />{' '}
                {post.pinned ? (
                  <Chip
                    label={'Pinned by: ' + context.publicUser.fname + ' ' + context.publicUser.lname}
                    size="small"
                    color="secondary"
                  />
                ) : null}
                <div>
                  {postAuthor.fname + ' ' + postAuthor.lname + ' ' + post.createdAtTime + ' ' + post.createdAtDate}
                </div>
              </div>
            )
          }
          action={
            post.authorId === context.userPublicId || context.privateUser.permissions.includes('admin') ? (
              <IconButton onClick={handleClickOpen}>
                <MoreVertIcon />
              </IconButton>
            ) : (
              <Tooltip title="You don't have permission to do this">
                <span>
                  <Button disabled>
                    <MoreVertIcon />
                  </Button>
                </span>
              </Tooltip>
            )
          }
        />
        {(post.authorId === context.userPublicId || context.privateUser.permissions.includes('admin')) && (
          <Menu
            aria-controls="options-menu"
            aria-haspopup="true"
            id="options-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose()
                setOpen(true)
              }}
            >
              Edit
            </MenuItem>
            <MenuItem onClick={handleCloseDelete}>Delete</MenuItem>

            {post.pinned && context.privateUser.permissions.includes('admin') ? (
              <MenuItem onClick={handleUnPinClose}>Unpin</MenuItem>
            ) : (
              <MenuItem onClick={handlePinClose}>Pin</MenuItem>
            )}
          </Menu>
        )}
        <EditPost isOpen={open} setClose={handleClosePost} id={post.id} title={post.title} desc={post.desc} />
        <CardContent>
          <Typography variant="body1" component="p">
            {post.content.text}
          </Typography>
        </CardContent>
        <CardActions className="actions">
          {renderLikeButton}
          <CreateReply parentId={post.id} />
          {post.hasRSVP ? <EventRsvp post={post} /> : null}
        </CardActions>
        {comments.map((comment) => (
          <div key={comment.id}>
            <Divider />
            <CardContent>
              <Box display="flex" alignItems="center">
                <Grid container direction="row" alignItems="flex-end" justify="space-between">
                  <Box width="67%">
                    <Typography>
                      <strong>{comment.author}:</strong> {comment.content}
                    </Typography>
                  </Box>
                  <Grid item>
                    <Typography variant="caption" color="textSecondary" textAlign="right">
                      {comment.createdAtTimeC + ' ' + comment.createdAtDateC}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </div>
        ))}
      </Card>
    </ThemeProvider>
  )
}

export default CommunityCard
